// useUpdateUserInfo 更新用户信息
import { ref, onMounted, onUnmounted } from 'vue'
import JLTools from '@/util/JLTools'
import api from '@/api'
import useStore from '@/store/pinia'
const { userStore } = useStore()

// 获取用户信息
const getUserInfo = (id) => {
  api.user.getUserInfo(id).then((res) => {
    // console.log('查看getUserInfo: ',res)
    if (!JLTools.isNull(res)) {
      queryCouponNum()
      getCollectProduct()
      queryAddress()
      userStore.getCartList()
    }
  })
}

// 查询劵数量
const queryCouponNum = () => {
  let date1 = new Date().toISOString()
  let now = date1.substring(0, date1.indexOf('Z') - 4) + 'Z'
  api.coupon
    .queryCouponNum({
      'userId.equals': userStore.userInfo.userId,
      'used.equals': false,
      'executed.equals': true,
      'effectEndAt.greaterThan': now
    })
    .then((res) => {
      userStore.setCouponNum(res)
    })
    .catch((err) => {})
}

// 获取我的收藏列表
const getCollectProduct = () => {
  if (JLTools.isNull(userStore.userInfo.userId)) return
  let parms = {
    'collected.equals': true,
    'logicalDeleted.equals': false,
    'userId.equals': userStore.userInfo.userId,
    size: 1000
  }
  api.product.queryAttentionNum(parms).then((res) => {
    userStore.setCollectProduct(res)
  })
}

// 获取我的地址列表
const queryAddress = () => {
  let parms = {
    'userId.equals': userStore.userInfo.userId
  }
  api.address
    .handleAddress(parms)
    .then((res) => {
      userStore.setAddressList(res)
    })
    .catch((res) => {
      return []
    })
}

// 处理成功之后跳转
const loginSuccessfully = () => {
  const canGoBack = history.state?.back
  if (canGoBack) {
    proxy.$router.back()
  } else {
    proxy.$router.replace({
      path: '/'
    })
  }
}

export { getUserInfo }
