<template>
  <div class="rowSC">
    <div style="min-width: 570px">
      <div class="checkbox rowSC" @click="checkedItem">
        <div :class="{ checked: modelValue }">
          <i class="iconfont icon-checkbox"></i>
        </div>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: ['modelValue'],
    name: 'CheckBox',
    data() {
      return {
        checked: this.modelValue
      }
    },
    methods: {
      checkedItem() {
        this.checked = !this.checked
        this.$emit('modelValue', this.checked)
      }
    }
  }
</script>

<style scoped lang="less">
  .checkbox {
    display: flex;
    cursor: pointer;
    user-select: none;

    > div:first-child {
      width: 20px;
      height: 20px;
      border: 1px #c8c8c8 solid;
      text-align: center;
      color: #ffffff;
      margin-right: 5px;
      .icon-checkbox {
        opacity: 0;
      }
      &.checked {
        background-color: var(--btnBgColor);
        .icon-checkbox {
          opacity: 1;
        }
      }
    }
  }
</style>
