<script setup>
  import { getCurrentInstance, onMounted, ref, reactive, defineEmits } from 'vue'
  import JLTools from '@/util/JLTools'
  import api from '@/api'
  import { useRouter, useRoute } from 'vue-router'
  import useStore from '@/store/pinia'
  const { userStore } = useStore()
  const router = useRouter()
  const { proxy } = getCurrentInstance()

  const data = reactive({
    // googleClientId: '48288705523-sckkkv4o7jogrbul4665hiffkmf7umhp.apps.googleusercontent.com',
    googleClientId: '200046307237-5v4cinn8bpc6ir5dbq6lol06n8qj092u.apps.googleusercontent.com',
    googleBtn: {
      theme: 'outline', // 按钮主题，使用'outline'可使按钮更简洁
      size: 'icon', // 只显示图标
      type: 'icon', // 只显示图标
      logo_alignment: 'center', // 图标居中
      text: '' // 移除默认的 "Sign in with Google" 文本
    },
    googleBtn2: {
      theme: 'filled_blue',
      size: 'large',
      shape: 'rectangular',
      logo_alignment: 'left',
      width: '320', // Optional, sets the button width
      text: 'signin_with',
      locale: 'en' // Optional, sets the button language to English
    }
  })

  // 初始化谷歌登录
  const initGoogle = () => {
    console.log('初始化谷歌')
    const script = document.createElement('script')
    script.src = 'https://accounts.google.com/gsi/client'
    document.body.appendChild(script)

    window.addEventListener('load', () => {
      window.google.accounts.id.initialize({
        // 主要就是填写client_id
        client_id: data.googleClientId,
        auto_select: false, // 启用自动选择
        callback: onGoogleSignIn
      })
      const parent = document.getElementById('google_btn')
      google.accounts.id.renderButton(parent, data.googleBtn)
      google.accounts.id.prompt()
    })
  }

  // 谷歌登录成功回调
  const onGoogleSignIn = (googleUser) => {
    console.log(googleUser, 'googleUser')
  }

  const onSignInError = (error) => {
    console.log(error, 'googleUser')
  }

  // 页面初始化
  onMounted(() => {
    initGoogle()
  })
</script>

<template>
  <div>
    <div id="google_btn"></div>
  </div>
</template>

<style lang="less" scoped>
  .other-login-btn {
    width: 100%;
    padding: 0 15px;
    margin-top: 20px;
    height: 48px;
    line-height: 48px;
    border-radius: 4px;
    border: 1px solid #cdd0d6;
    font-size: 20px;
    font-weight: 600;
    color: #606266;
    cursor: pointer;
  }
</style>
